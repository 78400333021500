<template>
	 <div class="main-contents code">
		<div class="tit">배치관리</div>
		<div class="search-box interval">
			<div class="select_wrap">
				<select v-model="srcDiv">
					<option value="" selected disabled hidden>배치작업명</option>
					<option value="1">배치작업명</option>
					<option value="2">Instance ID</option>
				</select>
			</div>
			<InputComp v-model="inputTxt" :placeholder="'관리자명'" @keyup.enter="getBatResultList('1');" />&nbsp;
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getBatResultList('1');"/>
		</div>
		<!-- 배치관리 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="6%">
					<col width="14%">
					<col width="6%">
					<col width="14%">
					<col width="14%">
					<col width="9%">
					<col width="10%">
					<col width="14%">
					<col width="10%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>Instance<br/>ID</th>
						<th>배치작업명</th>
						<th>실행 ID</th>
						<th>시작시간</th>
						<th>종료시간</th>
						<th>상태</th>
						<th>종료메세지</th>
						<th>파라미터</th>
						<th>결과 CONTEXT</th>
						<th>배치실행결과</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="list.length > 0">
						<tr v-for="(item, index) in list" :key="index">
							<td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
							<td class="score">{{item.jobInstanceId}}</td>
							<td class="score">{{item.jobName}}</td>
							<td class="score">{{item.jobExecutionId}}</td>
							<td class="score">{{item.startTime}}</td>
							<td class="score">{{item.endTime}}</td>
							<td class="score">{{item.status}}<span v-if="item.status != item.exitCode">({{item.exitCode}})</span></td>
							<td class="score">{{item.exitMessage | ellipsis(100)}}</td>
							<td class="score">{{item.paramJson}}</td>
							<td class="score">{{item.shortContext}}</td>
							<td class="score btn"><div class="btn" @click="movePage(item.jobExecutionId);">결과보기 &#9654;</div></td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="11" class="none">등록된 배치작업이 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			srcDiv : '1',
			inputTxt : '',
			list : [],
			pageInfo : {},
			pageIndex : 1,
		}
	},

	mounted() {
		this.getBatResultList('1');
	},

	components : {
		pagingComp
	},

	methods: {
		// 배치결과목록 조회
		getBatResultList(div) {
			var param = {};
			param.pageUnit = '25';
			param.pageSize = '10';
			param.pageIndex = this.pageIndex;
			if(div) param.pageIndex = '1';
			if(this.srcDiv == '1') param.jobName = this.inputTxt;
			if(this.srcDiv == '2') param.jobInstanceId = this.inputTxt;

			this.$.httpPost('/api/bat/adm/getBatchList', param)
				.then(res => {
					//console.log('getBatchList RESULT', res);

					this.list = res.data.list;
					this.pageInfo = res.data.pageInfo;

				}).catch(this.$.httpErrorCommon);
		},

		goPage(page) {
			this.pageIndex = page;
			this.getBatResultList();
		},

		movePage(id) {
			this.$router.push({name:'MAN937M02', params:{jobExecutionId:id}});
		}
	}
}
</script>